import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: process.env.VUE_APP_BACKEND_URL,
  withCredentials: true,
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})

Vue.prototype.$http = axiosIns

// Add interceptors to instance
axiosIns.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject(error.response.status)
  }
)

export default axiosIns
