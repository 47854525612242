import axios from '@axios'

const Api = {}

// // // // // //
// AUTH CRUD //
// // // // // //
/**
 * Login with default Cookies
 * @param {*} identifier
 * @param {*} password
 * @param {*} remember_me
 * @returns
 */
Api.login = (identifier, password) => {
  return axios.post('auth/login', { identifier, password })
}
/**
 * Retrieve Current User Data
 * @returns
 */
Api.getMe = () => {
  return axios.get('auth/me')
}

// // // // // //
// USERS CRUD //
// // // // // //
/**
 * Get Users List with Params
 * @param {*} queryParams
 * @returns
 */
Api.getUsers = (queryParams) => {
  return axios.get('/users', { params: queryParams })
}
/**
 * Get User from ID
 * @param {*} userId
 * @returns
 */
Api.getUser = (userId) => {
  return axios.get(`/users/${userId}`)
}
/**
 * Add User
 * @param {*} userData
 * @returns
 */
Api.addUser = (userData) => {
  return axios.post('/users', userData)
}
/**
 * Update User Info from ID
 * @param {*} userId
 * @param {*} userData
 * @returns
 */
Api.updateUser = (userId, userData) => {
  return axios.patch(`/users/${userId}`, userData)
}
/**
 * Delete User from ID
 * @param {*} userId
 * @returns
 */
Api.deleteUser = (userId) => {
  return axios.delete(`/users/${userId}`)
}

Api.getHeadquarter = (name) => {
  return axios.get(`headquarters?text_search=${name}`)
}

export default Api
