import Vue from 'vue'
import { Loader } from '@googlemaps/js-api-loader'

const loader = new Loader({
  apiKey: 'AIzaSyAjEOqCaein4YY7ky2P2lrGOib_cpwK0qI',
  libraries: ['places'],
  language: 'it',
})

const googleMapPlugin = Vue.observable({ ready: false, loadFailed: false })

Vue.use({
  install() {
    loader
      .load()
      .then(() => {
        googleMapPlugin.ready = true
      })
      .catch((e) => {
        googleMapPlugin.loadFailed = true
      })
    Vue.prototype.$googleMapPlugin = googleMapPlugin
  },
})
