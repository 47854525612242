import axios from '@axios'
import Api from '@/api/index'
import Abilities from './abilities'

export default {
  namespaced: true,
  state: {
    userData: null,
    apiToken: null,
    headquarter_id: null,
  },
  getters: {
    userData: state => state.userData,
  },
  mutations: {
    updateUserData(state, val) {
      state.userData = val
    },
    loggedIn(state, { userData }) {
      state.userData = userData
      // state.apiToken = apiToken
    },
    loggedOut(state) {
      state.userData = null
      state.headquarter_id = null
      // state.apiToken = null
    },
    setApiToken(state, apiToken) {
      state.apiToken = apiToken
    },
  },
  actions: {
    async loggedIn({ commit }, data) {
      commit('loggedIn', data)
      // this.$axios.setToken(data.apiToken, 'Bearer')
      // if (data.rememberMe) {
      //   this.$cookiz.set('apiToken', data.apiToken)
      // } else {
      //   this.$cookiz.set('apiToken', data.apiToken, {
      //     maxAge: 60 * 60 * 24, // 24 h
      //   })
      // }
    },
    async updateAuth({ commit }, $ability) {
      const response = await Api.getMe()
      const userData = response.data.user
      const roles = {
        admin: 'Admin',
        reception_hub: 'Reception',
        reception_colle: 'Reception',
        accountant: 'Contabilità',
        hr: 'Hr',
      }
      let role = roles[userData.role_code]
      if (!role && userData.is_approvator === 1) role = 'Approvatore'
      userData.ability = Abilities[role]
      $ability.update(userData.ability)
      localStorage.setItem('userData', JSON.stringify(userData))
      commit('updateUserData', response.data.user)
    },
    async logout({ commit }) {
      localStorage.removeItem('headquarter_id')
      localStorage.removeItem('headquarter_name')
      commit('loggedOut')
      // this.$axios.$get('/auth/logout').catch(err => {
      // 	// ignore this error
      // });
      // this.$cookiz.remove('apiToken')
      // this.$axios.setToken(false)
    },
  },
}
